#main-logo {
    font-family: 'Work Sans', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #f35a32;
}

.alt-logo-container {
    font-family: 'Work Sans', sans-serif;
    font-size: 5rem;
    font-weight: 700;
    // color: #f35a32;
    color: #fefc8b;
    display: none;
}

.small-screen-logo-container {
    width: 100%;
    display: none;
}

.nav-link {
    // color: #1eb788;
    color: #118f69;
    margin-right: 1rem;
    font-weight: bold;

}

.alt-nav {
    display: none;
    // visibility: hidden;
}

div.navigation {
    width: 100%;
    // padding: 0% 0% 2% 0%;
    position: fixed;
    z-index: 1000;
    background-color: #98d9ec;
    margin: 0%;
    padding: 0% 10% 1em;
}

#alt-logo-container:hover {
    color: #fefc8b;
}

a:hover {
    color: #3167af;

}

@media only screen and (max-width: 500px) and (min-width: 320px) {


    .navigation {
        width: 100%;
        margin: 0%;
        padding: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .nav-links-container {
        display: none;
    }

    .main-logo-container {
        display: none;

    }

    .small-screen-logo-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 1rem;

    }
    #small-screen-logo {
        display: inline-block;
        font-family: 'Work Sans', sans-serif;
        font-size: 1.75rem;
        font-weight: 700;
        color: #118f69;
    }

    .alt-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1.5em;
        background-color: #f35a32;
        position: fixed;
        bottom: 0;
        justify-content: center;
        align-items: center;
        z-index: 1000;

     

        div.alt-menu-items {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: .75rem;
            padding-bottom: 1rem;

        }

        .alt-nav-link {
       
            color: white;
            font-weight: bold;
            margin-right: .5rem;

        }
    }
}

@media only screen and (max-width: 830px) and (min-width: 501px) {

    .navigation {
        width: 100%;
        margin: 0%;
        padding: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .nav-links-container {
        display: none;
    }

    .main-logo-container {
        display: none;

    }

    .small-screen-logo-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-top: 1rem;

    }
    #small-screen-logo {
        display: inline-block;
        font-family: 'Work Sans', sans-serif;
        font-size: 3rem;
        font-weight: 700;
        color: #118f69;
    }

    .alt-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1.5em;
        background-color: #f35a32;
        position: fixed;
        bottom: 0;
        justify-content: center;
        align-items: center;
        z-index: 1000;

     

        div.alt-menu-items {
            width: 100%;
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            font-size: 1rem;
            padding-bottom: 1rem;

        }

        .alt-nav-link {
       
            color: white;
            font-weight: bold;
            margin-right: 1rem;

        }
    }
}

@media only screen and (max-width: 1320px) and (min-width: 831px){

    .alt-logo-container {
        font-size: 4rem;
    }

}