.home-container {
    margin: 8% 0% 0% 0%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: white;

}

.splash-image {
    width: 100%;
}

.splash {
    width: 100%;
    height: 100%;

}


.splash-text {
    position: absolute;
    z-index: 1;
    margin: -45% 10% 0% 0%;
    padding: 0 .5em .25em .5em;
    font-size: 4rem;
    background-color: #f35a32;
    width: 80%;
}

#splash-text-two {
    margin: -15% 10% 0% -45%;
    width: 35%;
}

.splash-text:hover {
    color: #3167af;
    background-color: white;
}

@media only screen and (max-width: 450px) and (min-width: 320px) {
 

    .home-container {
        margin: -45% 0% 0% 0%;
        // width: 560px;
        display: flex;
    }
#splash-text-one {
    display: none;
}
    .splash-text {
        padding: 0 .5em .25em .5em;
        font-size: 2rem;
        width: 400px;   
        margin: 100% 0% 0% 40%;
    }
    
    #splash-text-two {
        width: 250px;
        margin: 110% 50% 0% 40%; 
    }

    .splash-image {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);
        display: flex;

        img.splash {
            margin-left: 70%;
            width: 450px;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 451px) {

    .home-container {
        margin: -25% 0% 0% 0%;
  
    }

    .splash-text {

        padding: 0 .5em .25em .5em;
        font-size: 2rem;
        width: 400px;  
        margin: -20% 0% 0% 0%;
   
    }
    
    #splash-text-two {
        width: 400px;
        margin: 40% 0% 0% 0%;

        
    }

    .splash-image {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);

        img.splash {
            width: 1300px;
            height: 100%;
        }
    }
}


@media only screen and (max-width: 775px) and (min-width: 561px) {

    .home-container {
        margin: 0% 0% 0% 0%;

    
    }

    .splash-text {

        margin: -70% 10% 0% 0%;
        padding: 0 .5em .25em .5em;
        font-size: 1.75rem;
   
    }
    
    #splash-text-two {
        margin: -25% 10% 0% -45%;
        width: 35%;
    }

    .splash-image {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);

        img.splash {
            width: 1300px;
            height: 100%;
        }
    }
}


@media only screen and (max-width: 1000px) and (min-width: 776px) {

    .home-container {
        margin: 5% 0% 0% 0%;

    
    }

    .splash-text {

        margin: -50% 10% 0% 0%;
        padding: 0 .5em .25em .5em;
        font-size: 2.5rem;
   
    }
    
    #splash-text-two {
        margin: 0% 10% 0% -45%;
        width: 35%;
    }
    .splash-image {
        width: 100%;
        height: 100%;
        transform: rotate(90deg);

        img.splash {
            width: 1500px;
            height: 100%;
        }
    }
    
}


@media only screen and (max-width: 1200px) and (min-width: 1001px) {

    .home-container {
        margin: 12% 0% 0% 0%;

    
    }

    .splash-text {

        margin: -30% 10% 0% 0%;
        padding: 0 .5em .25em .5em;
        font-size: 3rem;
   
    }
    
    #splash-text-two {
        margin: 15% 10% 0% -45%;
        width: 35%;
    }
}

@media only screen and (max-width: 1590px) and (min-width: 1201px) {

    .home-container {
        margin: 10% 0% 0% 0%;

    
    }

    .splash-text {
        font-size: 3rem;
    }

}