.options-card {
background-color: white;
width: 225px;
height: 170px;
margin: 1rem;
border-radius: 5%;

.options-card-text{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h5 {
    font-size: 1rem; 
    margin: 0 0 .5rem 0;
    color: #3167af;
}

p {
    font-size: .8rem;
    margin: 0;
    color: #3167af;
}

button {
    // background-color: #fefc8b;
    padding: .5rem .5rem;
    margin-top: .5rem;
    font-size: 1rem;
    // color: #98d9ec;
    font-weight: bold;
    background-color: #98d9ec;
    border: none;
    font-family: 'Bitter', serif;
    border-radius: 20px;
    text-decoration: none;
    color: white;
}

button:hover {
    background-color: #98d9ec;
    border: dotted #3167af 3px;
    color: #3167af;
}

button:active {
    color: white;
    border: dotted white 3px;
}

}

