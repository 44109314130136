
.container {
    display: flex;
    width: 80%;
    flex-direction: column;
    margin: 10% 5% 1% 5%;
    justify-content: center;
    align-items: center;
    font-family: 'Bitter', serif;
    font-size: 1rem;
    color: #118f69;
    line-height: 2rem;
    background-color: white;
    border-radius: 15%;
    padding-bottom: 4rem;

    h1 {
        color: #3167af;
        font-family: 'Work Sans', sans-serif;
        font-size: 2rem;
       
    }

}

.meal-plan {
    display: flex;
    flex-direction: column;
    color: white;
    color: #118f69;
    margin-top: 2rem;
    margin-bottom: 2rem;

    h1 {
        text-align: center;
        
    }

}

.groceries {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: minmax(100px, auto) auto max-content fit-content(200px);
    gap: .5rem;

    .aisle-cat-container {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        margin: 0 5%;
    
    }

    h4 {
        text-decoration: underline;
    }

}

@media only screen and (max-width: 550px) and (min-width: 320px) {

    .container {
        background-color: transparent;
    }

    .groceries {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(100px, auto) auto max-content fit-content(200px);
        gap: .5rem;
    }

    .print-page-container {
        margin-bottom: 3rem;
    }

}

@media only screen and (max-width: 1000px) and (min-width: 551px) {

    .container {
        background-color: transparent;
    }

    .groceries {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(100px, auto) auto max-content fit-content(200px);
        gap: .5rem;
    }

    .print-page-container {
        margin-bottom: 3rem;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 1001px) {

    .container {
        background-color: transparent;
    }


}

@media only screen and (max-width: 1700px) and (min-width: 1201px) {

    .container {
        border-radius: 5%;
        padding: 0% 2rem 2rem 2rem;
    }


}