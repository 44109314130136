.cart-container {

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10% 20%;
    color: #3167af;
    font-family: 'Bitter', serif;
    font-weight: 500;
    letter-spacing: 1.65px;
    line-height: 1.85rem;
    font-size: 1.2rem;
   

    .cart-line-item{
        margin-top: 3%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        
    }

    .title {
        font-weight: bold;
    }

    .cart-photo {
        width: 75px;
    }

    .totals {
        margin: 5% 0%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
    }

    .small-button {
        margin: 0;
        padding: .25rem .5rem;
        font-size: .85rem;
        font-weight: bold;
        background-color: #63b199;
        border: none;
        font-family: 'Bitter', serif;
        border-radius: 20px;
        text-decoration: none;
        color: white;
    }

    .small-button:hover {
        background-color: #118f69;
        // color: #63b199;

    }

    .small-button:active{
        color: #118f69;
        background-color: white;
        border: dotted #118f69 3px;
    }
 
}

.checkout {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
}
.large-button  {
    // background-color: #f35a32;
    background-color: #fb7c27;
    border: none;
    font-family: 'Bitter', serif;
    border-radius: 20px;
    text-decoration: none;
    font-size: 1.5rem;
    padding: .75rem 1rem;
    color: white;
    width: 250px;
    margin-top: 1rem;
}

.large-button:hover{
    background-color: white;
    color: #f35a32;
}

.large-button:active{
    border: dotted #f35a32 3px;

}

@media only screen and (max-width: 520px) and (min-width: 320px){
    .cart-photo {
        display: none;
    }
  

    .cart-container {
        margin: 15% 5%;
        font-size: 1rem;


        h1 {
            margin-bottom: 2rem;
            font-size: 1.75rem;
        }
    }
}

@media only screen and (max-width: 760px) and (min-width: 521px){
  
    .cart-container {
        margin: 10% 5%;

    }
}