
.product-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10% 15%;
}
.product-page {

    display: flex;
    flex-direction: row;
    justify-content: center;

}

.product-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 50%;
    color: #0b4da3;
    font-family: 'Bitter', serif;
    font-weight: 500;
    letter-spacing: 1.65px;
    line-height: 1.85rem;
    font-size: 1.2rem;
}
h1 {
    margin-bottom: 1rem;
}
h4 {
    margin-top: 1rem;
}

.italicize {
    font-style: italic;
}
.large-button  {
    background-color: #fb7c27;
    border: none;
    font-family: 'Bitter', serif;
    border-radius: 20px;
    text-decoration: none;
    font-size: 1.5rem;
    padding: .75rem 1rem;
    color: white;
    width: 250px;
    margin-top: 1rem;
}

.large-button:hover{
    background-color: white;
    color: #f35a32;
}

.large-button:active{
    border: dotted #f35a32 3px;

}

.product-image {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

img {
    width: 80%;
}

@media only screen and (max-width: 500px) and (min-width: 320px) {


    .product-page {
        flex-direction: column;  
        justify-content: center;
        align-items: center;  
    }
    .product-container {
        margin: 20% 5%;
    }

    .product-text {
        width: 100%;
        font-size: 1rem;
    }

    .product-image {
        margin-bottom: 2rem;
    }

    img {
        width: 100%;
    }
    

}

@media only screen and (max-width: 750px) and (min-width: 501px) {

    .product-page {
        flex-direction: column;  
        justify-content: center;
        align-items: center;  
    }
    .product-container {
        margin: 20% 5%;
    }

    .product-text {
        width: 100%;

    }

    .product-image {
        margin-bottom: 2rem;
    }

    img {
        width: 100%;
    }
    

}
@media only screen and (max-width: 1200px) and (min-width: 751px) {

    .product-container {

        margin: 20% 5%;
    }

}
@media only screen and (max-width: 1500px) and (min-width: 1201px){

    .product-container {

        margin: 15% 10%;
    }
}