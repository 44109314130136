.my-meals-card {
    background-color: white;
    width: 225px;
    height: 180px;
    margin: 1rem;
    border-radius: 5%;
    
 
    .my-meals-card-text{
        margin: .5rem .75rem .75rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .x-button-div {
        display: flex;
        width: 100%;
        justify-content: flex-end;
 
        .small-x-button {
            margin: 0;
            padding: .25rem .5rem;
            font-size: .75rem;
            background-color: #63b199;
            border: none;
            font-family: 'Bitter', serif;
            border-radius: 20px;
            text-decoration: none;
            color: white;
        }

        .small-x-button:hover {
            background-color: #118f69;
            // color: #63b199;

        }

        .small-x-button:active{
            color: #118f69;
            background-color: white;
            border: dotted #118f69 3px;
        }
    }
    
    h5 {
        font-size: 1rem; 
        margin: 0;
        color: #118f69;
    }
    
    p {
        font-size: 1rem;
        margin: 0;
        color: #118f69;
    }
    
    .buttons-div{
        display: flex;
        width: 100%;
    }
    button {
        // background-color: #fefc8b;
        padding: .5rem .5rem;
        margin-top: .55rem;
        font-size: 1rem;
        // color: #98d9ec;
        font-weight: bold;
        background-color: #118f69;
        margin-right: .75rem;
        border: none;
        font-family: 'Bitter', serif;
        border-radius: 20px;
        text-decoration: none;
        color: white;
    }
    
    button:hover {
        background-color: white;
        border: dotted #118f69 3px;
        color: #118f69;
    }
    
    button:active {
        color: #98d9ec;
        border: dotted #98d9ec 3px;
    }
    
    }

