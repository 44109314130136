.contact-container {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5%;

    h1 {
        color: #3167af;
        font-family: 'Work Sans', sans-serif;
        font-size: 4rem;
    }

    h1:hover {
        color: #fefc8b;
    }
}

form{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: 'Bitter', serif;
    font-size: 1.25rem;
    color: #118f69;
    line-height: 2rem;
    

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    label {
        margin: 0 1rem;
    }
    input {
        border: none;
        height: 2rem;
        width: 13rem;
        
    }
    textarea {
        margin-top: 3rem;
        border: dotted #3167af;
    }

    button {
        margin-top: 3rem;
    }

    .large-button  {
        background-color: #fb7c27;
        border: none;
        font-family: 'Bitter', serif;
        border-radius: 20px;
        text-decoration: none;
        font-size: 1.5rem;
        padding: .75rem 1rem;
        color: white;
        width: 250px;
    }
    
    .large-button:hover{
        background-color: white;
        color: #f35a32;
    }
    
    .large-button:active{
        border: dotted #f35a32 3px;
    }

    .text-area-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
}

@media only screen and (max-width: 805px) and (min-width: 320px) {

    .contact-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10% 5%;

        h1 {

            font-size: 3rem;
        }

        .info {
            width: 100%;
            flex-direction: column;
        }

            textarea {
                width: 100%;
            }
   
    }

}

