.shop-container {
    width: 100%;
    margin: 5% 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0%;
}

.shop-grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(100px, auto) auto max-content fit-content(200px);
    gap: 3%;
    place-items: center;
    margin: 0%;

.product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
}

.product-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
    img {
        width: 300px;
        height: 400px;
        margin: auto;
    }

    .namedescription {
        font-size: 1.75rem;
        font-weight: bold;
        color:#3167af;
        margin-top: 1rem;
      
    }

   .namedescription:hover {
color: #fb7c27;
   }

   .price {
    font-size: 1rem;
    margin-top: .75rem;
    font-weight: bold;
    color: #118f69;
    

   }
   .price:hover{
    color:#3167af;
   }
}


@media only screen and (max-width: 800px) and (min-width: 320px) {

    .shop-container {
        width: 100%;
        margin: 25% 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0%;
    }
    
    .shop-grid {
   
        grid-template-columns: 1fr;

    }

}
@media only screen and (max-width: 1240px) and (min-width: 801px) {

    .shop-container {
        width: 100%;
        margin: 15% 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0%;
    }
    
    .shop-grid {
   
        grid-template-columns: 1fr 1fr;
    

    }

}
@media only screen and (max-width: 1660px) and (min-width: 1241px) {

    .shop-container {
        width: 100%;
        margin: 5% 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0%;
    }
    
    .shop-grid {
   
        grid-template-columns: 1fr 1fr 1fr;
    

    }


}