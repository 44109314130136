.App {
    display: flex;
    justify-content: center;
    width: 100%;
    
}

div#root{
    display: flex;
    flex-shrink: none;
    width: 100%;
    margin: auto;
   
    
    .navigation {
        width: 100%;
        // padding-left: 10%;
    }
    
  }